import React ,{ Component } from 'react';
import Layout from "../components/layout.js";
import BackgroundVideo from "../components/backgroundVideo.js";
import SEO from "../components/seo"
import '../fonts/GothamNarrow-Book.woff';
import '../fonts/GothamNarrow-Bold.woff';
import '../fonts/GothamNarrow-Medium.woff';

// UIkit
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/css/uikit-core.min.css";
import "uikit/dist/js/uikit.min.js";
// Custom style
import "../styles/scss/main.scss";


class IndexPage extends Component {

	render() {
		return (
			<Layout pagePath={this?.props?.path}>
				<SEO
					title="Home page"
					locationOrigin={this.props.location.origin}
					domen={this.props.location.origin}
				/>
				<BackgroundVideo />
			</Layout>
		);
	}
};

export default IndexPage;
