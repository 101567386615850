import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const getData = graphql`
	query {
		allWpPage(filter: { uri: { eq: "/" } }) {
			nodes {
				title
				id
				homepage {
					homepageVideo
				}
				uri
			}
		}
	}
`;

function BackgroundVideo() {
	const data = useStaticQuery(getData);
	const videoLink = data.allWpPage.nodes[0].homepage.homepageVideo || "/";

	return (
		<>
			<div className="video-wrapper">
				<iframe
					src={`${videoLink}?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0`}
					frameBorder="0"
					webkitallowfullscreen=""
					mozallowfullscreen=""
					allowFullScreen=""
					className="backgroundVideo"
				></iframe>
			<img src={`/rings.svg`} alt="loader" className="loader" />
			</div>
		</>
	);
}

export default BackgroundVideo;
